import { useQuery } from "@apollo/client";
import { ButtonRow } from "components/ButtonRow";
import { Loading } from "components/Loading";
import React from "react";
import { FaArrowRight, FaDownload } from "react-icons/fa";
import { Link, Redirect, useLocation } from "react-router-dom";

export default function ImportEntry({
  manualCreateURL,
  nextURL,
  items,
}: {
  manualCreateURL: string;
  nextURL: string;
  items: any[];
}) {
  const location = useLocation();

  return (
    <div>
      <p>
        We can connect directly with your financial institutions to gather some
        of the information we need about your financial accounts. The import
        process is secure, and we only save the information your lawyer will
        need to file your case.
      </p>
      <p>If you prefer, you may add your accounts manually instead.</p>

      <ButtonRow
        left={<Link to={manualCreateURL}>I&rsquo;ll add them manually</Link>}
        right={
          <Link
            className="primary btn"
            to={`/new-filing/financial-import?from=${location.pathname}`}
          >
            <FaDownload />
            <span>Import accounts</span>
          </Link>
        }
      />
    </div>
  );
}
