import { useGetSession } from "api/graphql/Session";

export default function LawFirmNotice() {
  const { data } = useGetSession();
  const lawFirmName = data?.session?.user?.referralFirm?.displayName;
  const documentsDueDate = data?.session?.user?.documentsDueDate
    ? new Date(data.session.user.documentsDueDate).toLocaleDateString("en-US", {
        dateStyle: "long",
      })
    : null;
  return (
    <>
      {lawFirmName && (
        <div className="p-4 bg-yellow-100">
          <div>
            Your law firm is{" "}
            <span className="text-xl font-semibold">{lawFirmName}</span>
          </div>
          <div>
            If this is not correct, please contact us at{" "}
            <a href="mailto:support@lexria.com">support@lexria.com</a>
          </div>
          {documentsDueDate && (
            <div>
              Note: Your documents due date is{" "}
              <span className="text-xl font-semibold">{documentsDueDate}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
