import { useUpsertEmploymentRecord } from "api/graphql/EmploymentRecord";
import EmployerApiModal from "./EmployerApiModal";
import { useGenerateEmployerLinkToken } from "api/graphql/EmployerLinkToken";
import AutocompleteApiEmployer from "components/autocomplete/ApiEmployer";
import { ButtonRow } from "components/ButtonRow";
import { useEditorBuffer } from "components/EditorState/hooks";
import { HiddenInput } from "components/HiddenInput";
import { LearnMore } from "components/LearnMore";
import { TextInput } from "components/TextInput";
import { WizardRouteChildProps } from "components/Wizard";
import { WizardNavigation } from "components/WizardNavigation";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { bool, object, string } from "yup";

type Employer = ArrayItemType<SearchApiEmployers["employerSearch"]>;
type EmployerLinkToken = {
  token: string;
  employerId: string;
  provider: string;
};

export const OverviewSchema = object({
  companyName: string().required(
    "Please enter the name of the company you work for."
  ),
  jobTitle: string().required("Please enter your job title."),
  ownedBusinessId: string().optional().nullable(),
  employerLinkToken: object().nullable(),
  useEmployerApi: bool().when("employerLinkToken", {
    is: (value) => value != null,
    then: bool().required(
      "You must decide if you want to help us automatically fill the forms."
    ),
    otherwise: bool().nullable(),
  }),
}).required();

export type OverviewFormState = ReturnType<typeof OverviewSchema.validateSync>;

export function Overview({ isEdit, navProps }: WizardRouteChildProps) {
  const form = useFormContext<OverviewFormState>();
  const [{ buffer }] = useEditorBuffer();
  const [apiEmployer, setApiEmployer] = useState<Employer>();
  const [showEmployerapiModal, setShowEmployerApiModal] = useState(false);
  const [offerEmployerApi, setOfferEmployerApi] = useState(false);
  const [hasdeclinedEmployerApi, setHasDeclinedEmployerApi] = useState(false);
  const [showEmployerApiError, setShowEmployerApiError] = useState(false);
  const generateEmployerLinkToken = useGenerateEmployerLinkToken();
  const [upsertEmploymentRecord] = useUpsertEmploymentRecord();
  const history = useHistory();

  function isEmployerApiValuesValid() {
    const companyName = form.getValues("companyName");
    return (
      !!companyName && !!apiEmployer?.name && companyName === apiEmployer.name
    );
  }

  function shouldOfferEmployerApi() {
    return (
      isEmployerApiValuesValid() &&
      !!form.getValues("jobTitle") &&
      !!form.getValues("employerLinkToken")
    );
  }

  const fromOwnedBusiness = !!buffer.ownedBusinessId;

  return (
    <>
      {!fromOwnedBusiness && (
        <EmployerApiModal
          isOpen={showEmployerapiModal}
          onSuccess={async ({ accountId, userId, linkItemId }) => {
            const employerLinkToken = form.getValues("employerLinkToken");
            const connectionInfo = {
              ...form.getValues(["companyName", "jobTitle"]),
              employerLinkToken: {
                provider: employerLinkToken?.provider,
                employerId: employerLinkToken?.employerId,
                accountId: accountId,
              },
            };
            await upsertEmploymentRecord({
              ...connectionInfo,
            });
            history.push("../../summary");
          }}
          onError={() => {
            setShowEmployerApiModal(false);
            setShowEmployerApiError(true);
            setOfferEmployerApi(false);
            form.setValue("useEmployerApi", false);
            form.setValue("employerLinkToken", null);
          }}
          onClose={() => setShowEmployerApiModal(false)}
          apiEmployer={apiEmployer}
          employerLinkToken={form.getValues("employerLinkToken")?.token}
        />
      )}

      <div className="form-row">
        {!fromOwnedBusiness && (
          <>
            <TextInput
              name="companyName"
              label="Company name"
              width="200px"
              maxLength={128}
              autoComplete="off"
              autoFocus={!isEdit}
              onBlur={() => {
                if (!isEmployerApiValuesValid()) {
                  form.setValue("employerLinkToken", null);
                  setApiEmployer(null);
                }
                if (shouldOfferEmployerApi()) {
                  setOfferEmployerApi(true);
                }
              }}
              learnMore={
                <LearnMore>
                  <h4>
                    What if I or my family member is self-employed and
                    don&rsquo;t have a company name?
                  </h4>
                  <p>Enter the company name as &quot;Self employed&quot;.</p>
                </LearnMore>
              }
            />
            <AutocompleteApiEmployer
              inputSelector={'[name="companyName"]'}
              onEmployerSearchSelected={(employer) => {
                setApiEmployer(employer);
                form.setValue("companyName", employer.name);
                generateEmployerLinkToken({
                  provider: employer.provider,
                  employerId: employer.remoteId,
                }).then((result) => {
                  form.setValue(
                    "employerLinkToken",
                    result.data.employerLinkToken
                  );
                });
              }}
            />
            <HiddenInput name="employerLinkToken" />
          </>
        )}

        <TextInput
          name="jobTitle"
          label="Job title"
          width="200px"
          maxLength={255}
          autoFocus={fromOwnedBusiness}
          onBlur={() => {
            if (shouldOfferEmployerApi()) {
              setOfferEmployerApi(true);
            }
          }}
        />

        {fromOwnedBusiness && (
          <>
            <HiddenInput name="companyName" />
            <HiddenInput name="ownedBusinessId" />
          </>
        )}
      </div>

      {/* Trick to force the form not to proceed if the question has not been answered... */}
      <HiddenInput name="useEmployerApi" />

      {offerEmployerApi && (
        <>
          <p>
            Do you want to automatically retrieve your employment information
            and pay stubs?
          </p>
          <ButtonRow
            style={{ marginBottom: "8px" }}
            left={
              <button
                onClick={() => {
                  setShowEmployerApiModal(true);
                  form.setValue("useEmployerApi", "value"); // TODO: Why does using a boolean here cause the form to move to the index when choosing "Yes"?
                }}
                className="block btn primary"
              >
                Yes
              </button>
            }
            right={
              <button
                onClick={() => {
                  setShowEmployerApiModal(false);
                  setOfferEmployerApi(false);
                  setHasDeclinedEmployerApi(true);
                  form.setValue("useEmployerApi", false);
                  form.setValue("employerLinkToken", null);
                }}
                className="block btn secondary"
              >
                No
              </button>
            }
          />
        </>
      )}
      {hasdeclinedEmployerApi && (
        <p>
          Ok, we'll need you to enter your employment information manually.
          Click Next to continue.
        </p>
      )}

      {showEmployerApiError && (
        <p className="errors">
          We were unable to retrieve your employment and paystub history. You
          will need to complete this section manually.
        </p>
      )}

      <WizardNavigation {...navProps} />
    </>
  );
}
